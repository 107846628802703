@import '@/styles/buttons';
@import '@/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
    align-content: center;
    width: 100%;
    padding-bottom: 24px;
    @include screen('desktop') {
        align-items: flex-start;
        width: 100%;
        padding-bottom: 75px;
    }
}

.hr {
    @include screen('desktop') {
        padding: 32px 20px;
        width: 100%;
    }
    .line {
        height: 1px;
        background: $button-medium;
        opacity: 0.15;
        width: 100%;
    }
}
.btnWrap {
    padding: 24px 20px 0;
    .primary {
        margin: 0 auto;
    }
    @include screen('desktop') {
        padding: 24px 0 10px;
        width: 100%;
    }
}
.zeroTrips {
    text-align: center;
    padding: 0 20px 24px 20px;
}
.zeroIcon {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    padding-top: 32px;
}

.footer {
    @include screen('desktop') {
        display: none;
    }
}
