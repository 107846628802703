@import '@/styles/variables';
@import '@/styles/forms';
.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    textarea {
        line-height: 26px;
    }
}

.durationDisplayContainer {
    padding: 6px;
    border: 1px solid $button-light;
    border-radius: 12px;
    margin: 0 auto;
    width: 100%;
    max-width: 264px;
    @include screen('tablet') {
        max-width: 330px;
    }
}
.durationDisplay {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    background-color: $section-medium;
    border-radius: 12px;
    padding: 16px 48px;
}
.inline {
    display: inline;
}
.title {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
.submitButton {
    align-self: center;
}
.sliderLabel {
    color: #455d54;
}
