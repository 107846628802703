.container {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 0 20px;
    height: calc(100% - 184px);
}
.title {
    display: flex;
    flex-direction: column;
    gap: 12px;
}
