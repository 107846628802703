@import '@/styles/forms';
@import '@/styles/mixins';

.container {
    position: relative;
    height: 250px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    img {
        object-fit: cover;
    }
}

.button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    border-radius: 12px;
    border: 2px dashed var(--Green-300, $button-light);
    background-color: white;
    cursor: pointer;
}
.form {
    margin: 0 auto;
}
.btnGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    padding-bottom: 40px;
    padding-top: 32px;
    width: 100%;

    .loaderBtn {
        display: flex;
        height: 48px;
        max-width: 560px;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        border-radius: 12px;
        text-decoration: none;
        cursor: pointer;
        background-color: #455d54;
        color: #ebe9e8;
        font-size: 16px;
        font-weight: 700;
        line-height: 21px;
        letter-spacing: 0.64px;
    }

    @include screen('xsmall-tablet') {
        width: 400px;
    }
}

.title {
    margin-bottom: 16px;
}
.addButton {
    border: 1.5px solid $button-inactive;
    color: $button-inactive;
    border-radius: 12px;
    display: flex;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    gap: 12px;
    align-self: center;
    @include screen('xsmall-tablet') {
        max-width: 400px;
    }
}
.addActive {
    border: 1.5px solid $button-medium;
    color: $button-medium;
}
.root {
    padding: 0 20px;
}
.success {
    margin: 0 auto;
    display: flex;
    gap: 12px;
    max-width: $mobile-width;
    padding: 16px;
    margin-top: 12px;
    align-items: center;
    border-radius: 8px;
    max-width: fit-content;
    background-color: $alert-success;
    .text {
        color: $text-on-alert-success;
    }
}
.pad {
    margin-top: 72px;
}
.imgpad {
    margin-bottom: 24px;
}

.checkbox {
    * {
        cursor: pointer;
    }
}
