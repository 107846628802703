@import '@/styles/mixins';

.container {
    padding: 24px 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 16px;
    // overflow-x: hidden;
    max-width: 100%;
    @include screen('desktop') {
        padding: 24px 20px;
    }

    .bar {
        position: relative;
        background-color: $primary;

        width: calc(100% - 88px);
        flex-grow: 2;
    }
    .empty {
        height: 4px;
        background-color: $section-medium;
    }
    .fill {
        height: 4px;
        background-color: $button-light;
    }
}
.icons {
    display: flex;
    width: 24px;
    height: 24px;
    align-items: center;
    color: $button-dark;
    z-index: 2;

}
