@import '@/styles/mixins';

.container {
    display: flex;
    height: 184px;
    @include screen('xsmall-tablet') {
        height: 250px;
    }

    .imageInputStyled {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 24px;
        width: 100%;
        border-radius: 12px;
        border: 2px dashed var(--Green-300, $button-light);
        background-color: white;
        cursor: pointer;
        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 56px 20px;
            .row {
                display: flex;
                gap: 4px;
            }
        }
    }

    .previewWrap {
        overflow: hidden;
        border-radius: 20px;
        display: block;
        position: relative;
        height: 184px;
        width: 100%;
        @include screen('xsmall-tablet') {
            height: 250px;
        }

        img {
            object-fit: cover;
        }
    }
}

.required {
    color: $important;
}
