@import '@/styles/mixins';

.animatedDiv {
    position: relative;
    z-index: 100000;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 60px 50px;
    background-color: rgba(255, 255, 255, 0.01);
    animation: smoothBg 0.3s ease-in forwards;
}

.box {
    width: 100%;
    padding: 24px;
    border-radius: 18px;
    overflow: hidden;
    visibility: hidden;
    color: black;
    background-color: #ffffff;
    animation: popup 0.4s ease-in 1 forwards;
}

.btnClose {
    position: absolute;
    right: 24px;
    top: 24px;
    font-size: 25px;
    cursor: pointer;
    border-radius: 50%;
    z-index: 3;
    color: #2d3938;
}

.action,
.alert,
.confirm {
    max-width: 464px;
}

.title {
    margin-bottom: 10px;
}

.text {
    margin-bottom: 15px;
    letter-spacing: 0.73px;
}

.buttons {
    display: flex;
    gap: 56px;
    button {
        flex: 1;
    }
}

.info {
    max-width: 720px;
    animation-duration: 0.5s;
}

.fullscreen {
    width: auto;
    padding: 0;
    background-color: transparent;
}

@keyframes smoothBg {
    0% {
        backdrop-filter: blur(0);
    }

    to {
        backdrop-filter: blur(8px);
    }
}

@keyframes popup {
    0% {
        opacity: 0;
        visibility: hidden;
        transform: scale(0.3);
    }
    80% {
        transform: scale(1.05);
    }
    100% {
        opacity: 1;
        visibility: visible;
        transform: scale(1);
    }
}
