@import '@/styles/mixins';

    .tags {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        gap: 32px;
        padding: 0px 20px;
        
    }
