@import '@/styles/forms';

.root {
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.container {
    display: flex;
    flex-direction: column;
}
.tagGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 16px;
    align-self: center;
}
.intro {
    padding-top: 8px;
}
.tag {
    -webkit-tap-highlight-color: rgba(117, 137, 116, 0);
}
.submitButton {
    align-self: center;
}
