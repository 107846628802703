@import '@/styles/iconSelect';

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding-bottom: 24px;
    padding: 0 20px;
    i {
        font-size: 24px;
        &.disabled {
            color: #758974;
        }
    }
}
.title {
    flex-grow: 2;
}
.rowLabel {
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 1px solid $button-light;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.rowLeft {
    width: 222px;
    max-width: 100%;
}
.rowRight {
    display: flex;
    height: min-content;
    gap: 6px;
}
.extra {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 12px;

    textarea {
        min-width: 100%;
        font-size: 16px;
        line-height: 26px;
        font-family: $body-font-family;
    }
}
.field {
    width: 100%;
    max-width: calc(100% - 32px);
    padding: 12px 16px;
    background-color: $primary;
    border-radius: 12px;
    border-width: 0;
    font-size: 16px;
    &:focus {
        outline: 1px solid $button-light;
    }
    &::placeholder {
        color: $button-inactive;
        font-family: $body-font-family;
    }
}
.number {
    text-align: center;
    min-width: 35.22px;
    padding-left: 8px;
    padding-right: 8px;
}
.buttonbottom {
    padding-inline: 20px;
    margin-bottom: 26px;
}
