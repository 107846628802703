@import '@/styles/mixins';
$padding: 156px;
.root {
    @include screen('desktop') {
        padding-top: $padding;
        padding-right: 80px;
    }

    @include screen('medium-desktop') {
        padding-right: 0;
    }
    .container {
        @include screen('desktop') {
            min-height: calc(100vh - $padding);
            gap: 40px;
            display: flex;
            align-items: stretch;
            margin: 0;
        }
        @include screen('medium-desktop') {
            min-height: max-content;
            margin: 0 auto;
            padding-bottom: 36px;
            max-width: 1200px;
        }
        @include screen('big-desktop') {
            max-width: 1520px;
        }

        .profileInfo {
            @include screen('desktop') {
                max-width: 50%;
            }
            @include screen('medium-desktop') {
                width: 50%;
                max-width: 500px;
            }
        }
        .userView {
            width: 100%;
            @include screen('desktop') {
                min-width: calc(50% - 120px);
            }
        }
    }
}
