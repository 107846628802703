@import '@/styles/mixins';

.container {
    width: 100%;
    padding: 118px 20px 0 20px;
    max-width: 600px;
    margin: 0 auto;
    @include screen('desktop') {
        padding: 184px 20px 32px;
    }
}
