@import '@/styles/mixins';
.root {
    .container {
        margin: 0 auto;
        padding: 0 20px;
        max-width: 600px;
    }
    .head {
        @include screen('desktop') {
            padding-top: 22px;
        }
    }
}
