@import '@/styles/forms';
@import '@/styles/mixins';

.title {
    display: flex;
    justify-content: space-between;
    padding-bottom: 24px;
    color: $button-dark;
    .remove {
        color: $button-medium;
    }
}

.container {
    position: relative;
    height: 250px;
    width: 100%;
    border-radius: 16px;
    overflow: hidden;
    img {
        object-fit: cover;
    }
}
.label {
    padding-top: 70px;
}
.btnGroup {
    margin: 0 auto 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    width: 100%;
    max-width: 400px;
}
.root {
    // padding: 0 20px;
    // @include screen('xsmall-tablet') {
    //     padding: 0;
    // }
}
.success {
    display: flex;
    gap: 12px;

    max-width: fit-content;
    padding: 16px;
    margin: 12px auto 0;
    align-items: center;
    border-radius: 8px;
    background-color: $alert-success;
    .text {
        color: $text-on-alert-success;
    }
}
.label {
    padding-top: 12px;
}
