@import '@/styles/mixins';
.container {
    display: flex;
    gap: 12px;
    width: fit-content;
    // max-width: $mobile-width;
    padding: 16px;
    align-items: center;
    border-radius: 8px;
    margin: 20px auto 0;
}
.warning {
    background-color: $alert-warning;
    .text {
        color: $text-on-alert-warning;
    }
}
.error {
    background-color: $alert-error;
    .text {
        color: $text-on-alert-error;
    }
}
.success {
    background-color: $alert-success;
    .text {
        color: $text-on-alert-success;
    }
}
.info {
    background-color: $alert-info;
    .text {
        color: $text-on-alert-info;
    }
}
