@import '@/styles/mixins';

.calendarBox {
    padding: 0 !important;
    &.focus {
        outline: 1px solid $button-light;
    }
    .calendar {
        display: block;
    }
}

.label {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 6px;
    font-weight: bold;
}

.calendarWrap {
    position: relative;
}

.calendar {
    :global {
        .react-datepicker__close-icon {
            right: 30px;
            &:after {
                background-color: transparent;
                font-size: 23px;
                color: hsl(0, 0%, 80%);
            }
        }
    }
}

.inputStyle {
    position: relative;
    width: 100%;
    padding: 12px 16px;
    background-color: transparent;
    font-size: 16px;
    outline: none;
    border: none;
    line-height: 26px;
    &.placeholder {
        color: $button-inactive;
    }
    &:disabled {
        background-color: white;
        border-color: #ccd2ca;
        &::placeholder {
            color: #ccd2ca;
        }
    }
}
.chevron {
    position: absolute;
    right: 16px;
    font-size: 24px;
    transition: 300ms all;
    margin-top: 2px;

    &.hide {
        display: none;
    }

    &.turn {
        transform: rotate(180deg);
    }
}

.icon {
    position: absolute;
    right: 10px;
    top: 14px;
    font-size: 20px;
    pointer-events: none;
}

.popup {
    :global {
        .react-datepicker__header {
            background-color: #ccd2ca;
        }
    }
}

.error {
    .input {
        border-color: red;
    }
}

.controls {
    display: flex;
    justify-content: center;
    align-items: center;
}

.btn {
    margin: 0 3px;
    padding: 0;
    background-color: #ccd2ca;
    border: 1px solid #aeaeae;
    width: 20px;
    height: 20px;
    border-radius: 3px;
    cursor: pointer;
    &:disabled {
        pointer-events: none;
        background-color: #ccd2ca;
        opacity: 0.5;
    }
}

.select {
    border: 1px solid #aeaeae;
    border-radius: 3px;
    height: 20px;
    + .select {
        margin-left: 3px;
    }
}
