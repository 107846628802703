.container {
    width: 100vw;
    display: flex;
    flex-direction: column;
}
.inline {
    display: inline;
}
.paragraph {
    display: flex;
    flex-direction: column;
    padding-top: 32px;
    gap: 40px;
}
.center {
    align-self: center;
}
