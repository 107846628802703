@import '@/styles/forms';

.container {
    margin: 40px auto;
    padding: 0 20px;
    
}

.title {
    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 12px;
}

.inputWrapper {
    margin: 32px 0;
}
