@import '@/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
    align-content: center;
}
.form {
    align-self: center;
    max-width: 100%;
    padding: 0 20px 32px;
    @include screen('desktop') {
        padding: 0 0 32px;
    }
}
