@import '@/styles/uploadButton';
// @import '@/styles/buttons';
@import '@/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 600px;
    padding: 0 20px;
    margin: 0 auto;
}
.success {
    display: flex;
    gap: 12px;
    max-width: 350px;
    align-items: center;
    border-radius: 8px;
    padding: 16px;
    margin: 0 auto;
    background-color: $alert-success;
    .text {
        color: $text-on-alert-success;
    }

    @include screen('desktop') {
        width: 100%;
    }
}
.img {
    width: 100%;
    height: 250px;
    @include screen('desktop') {
    }
}
.btnGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    padding-bottom: 40px;
    width: 100%;
    max-width: 400px;
}

.videoIntro {
    align-self: center;
}

.imgWrap {
    position: relative;
    width: 100%;
    height: 250px;
    @include screen('desktop') {
    }
}
