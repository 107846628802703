@import '@/styles/forms';
@import '@/styles/mixins';
.container {
    display: flex;
    flex-direction: column;
    align-self: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.submitButton {
    align-self: center;
}
