@import '@/styles/mixins';

.root {
    transform: translateY(-5px);
}
.popup {
    position: absolute;
    top: -90px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    border: 2px solid $button-medium;
    &.hide {
        height: 0;
        width: 0;
    }
}

.modalWrap {
    display: flex;
    @include screen('desktop') {
        display: none;
    }

    .modal {
        max-width: 450px;
    }
    .modalInner {
        .label {
            margin-bottom: 16px;
            font-size: 20px;
            font-weight: 700;
            line-height: 26px;
            color: #2d3938;
        }

        .inputStyle {
            background-color: #ccd2ca;
            border-radius: 12px;
            display: flex;
            gap: 8px;
            justify-content: space-between;
            align-items: center;
            padding: 4px 5px 4px 16px;

            .expandableClass {
                height: fit-content;
                width: calc(100% - 102px);
            }
            .copyButton {
                cursor: pointer;
                width: 91px;
                border-radius: 8px;
                padding: 8px 16px;
                background-color: #455d54;
                transition: 300ms all;
                &.active {
                    background-color: #2d3938;
                }
                text-align: center;
                color: white;
                font-size: 16px;
                font-weight: 700;
                line-height: 21px;
                letter-spacing: 0.64px;
            }
        }
    }
}
.popupWrap {
    display: none;
    @include screen('desktop') {
        display: block;
    }
}
