@import '@/styles/forms';
.intro {
    display: flex;
    flex-direction: column;
    max-width: 560px;
    padding: 0px 20px;
    gap: 12px;

    .row {
        display: flex;
        gap: 8px;
        max-width: 100%;
        align-items: center;

        .title {
            color: $button-dark;
            font-size: 28px;
            font-weight: 700;
            line-height: 36px;
            letter-spacing: 0.56px;
        }
    }
}
.gdprConsentError {
    color: $important;
}