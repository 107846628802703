@import '@/styles/forms';
@import '@/styles/buttons';
@import '@/styles/mixins';

.container {
    display: flex;
    flex-direction: column;
}
.tagGroup {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-top: 16px;
    align-self: flex-start;
}
.intro {
    padding-top: 8px;
}
.tag {
    -webkit-tap-highlight-color: rgba(117, 137, 116, 0);
}
.btnGroup {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    padding-top: 32px;
    width: 100%;
    @include screen('xsmall-tablet') {
        width: 400px;
    }
}