@import '@/styles/variables';
.container {
    display: flex;
    align-items: center;
    gap: 8px;
    width: fit-content;
    border-radius: 20px;
    border: 1.5px solid rgba(117, 137, 116, 0.5);
    backdrop-filter: blur(2.5px);
    padding: 8px 16px;
    color: $button-medium;
    -webkit-tap-highlight-color: rgba(117, 137, 116, 0);
    cursor: pointer;
}

.selected {
    border-radius: 80px;
    border: 1.5px solid $button-light;
    background: rgba(117, 137, 116, 0.2);
    -webkit-tap-highlight-color: rgba(117, 137, 116, 0);
}
