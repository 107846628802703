@import '@/styles/_mixins';

.popup {
    visibility: hidden;
    opacity: 0;
    width: 0;
    height: 0;
    transition: opacity 0.3s ease-in-out;
    color: $text-on-primary;
    top: -80px;
    white-space: nowrap;

    &.show {
        visibility: visible;
        opacity: 1;
        width: fit-content;
        height: fit-content;
        position: absolute;
        border-radius: 20px;
        background-color: $primary;
        padding: 20px;
        &::after {
            content: '';
            position: absolute;
            bottom: -20px;
            left: 50%;
            transform: translateX(-50%) rotate(180deg);
            border-width: 10px;
            border-style: solid;
            border-color: transparent transparent $primary transparent;
            width: 0;
            height: 0;
        }
    }
    &.noTick {
        &::after {
            border-color: transparent transparent transparent transparent;
        }
    }
}
