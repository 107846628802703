@import '@/styles/mixins';

.root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 20px 0 20px;
    min-width: 100%;
    @include screen('desktop') {
        padding: 0;
    }

    .container {
        width: 100%;
        max-width: 100%;
    }
}

.commissionContainer {
    background-color: $section-medium;
    color: $button-medium;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    border-radius: 12px 12px 0px 0px;
    margin-top: 16px;

    .row,
    .row2 {
        display: flex;
        justify-content: space-between;
        padding: 24px 24px 8px 24px;
    }
    .row2 {
        padding: 0px 24px 24px 24px;
        justify-content: flex-start;
        gap: 8px;
        color: $button-light;
    }
    .showMore {
        display: flex;
        gap: 4px;
        padding: 0px 24px 16px 24px;
        align-self: flex-end;
        align-items: center;
    }
}
.amount {
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 26px;
    padding: 0px 24px 16px 24px;
}
.waitingSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 100%;
    padding-top: 40px;
    .waitingTitle {
        padding-top: 16px;
        padding-bottom: 4px;
    }
}
.detailsSection {
    margin-top: 8px;
    background-color: $section-medium;
    color: $button-medium;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.detailsTitle {
    color: $button-light;
    padding: 16px;
    position: relative;
}
.tripRow {
    display: flex;
    justify-content: space-between;
    padding: 16px;
    position: relative;
}
.tripRow::before,
.detailsTitle::before {
    content: '';
    background-color: $button-medium;
    width: 8px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}
.date {
    color: $inactive;
}
.seeMore {
    display: flex;
    max-width: 100%;
    justify-content: center;
    align-self: center;
    padding-top: 16px;
    color: $button-medium;
}
