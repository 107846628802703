.innerBar {
    background-color: #455d54;
    cursor: pointer;
    height: 100%;
    border-radius: 5px;
    position: relative;
    transition: width 0.3s ease;
}

.thumb {
    position: absolute;
    width: 24px;
    height: 28px;
    border-radius: 50%;
    top: 50%;
    right: 0;
    transform: translate(12px, -50%);
    background-color: #ebe9e8;

    .innerThumb {
        position: absolute;
        top: 50%;
        transform: translate(-4px, -50%);
        border-radius: 50%;
        background-color: #455d54;
        width: 24px;
        height: 24px;
    }
}

.bar {
    position: relative;
    height: 15px;
    width: 100%;
    background-color: #ccd2ca;
    border-radius: 5px;
    cursor: pointer;
}
