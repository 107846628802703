@import '@/styles/mixins';

.root {
    .success {
        margin: 20px auto 0;
        display: flex;
        gap: 12px;
        max-width: $mobile-width;
        padding: 16px;
        align-items: center;
        border-radius: 8px;
        background-color: $alert-success;
        .text {
            color: $text-on-alert-success;
        }
    }
}
