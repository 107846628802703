.container {
    padding: 0 20px;
    display: flex;
    flex-direction: column;
    .button {
        padding: 32px 20px 40px 20px;
        max-width: 400px;
        width: 100%;
        align-self: center;
        .link {
            color: inherit;
            text-decoration: none;
        }
    }
    .paragraph {
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
    }
}
