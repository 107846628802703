@import '@/styles/mixins';
@import '@/styles/forms';

.container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    padding: 0 20px 26px;
    max-width: 560px;
    margin: 0 auto;
    @include screen('desktop') {
        padding-bottom: 56px;
    }
}
.btnGroup {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-self: center;
    width: 100%;
    max-width: 400px;
}
.accTitle {
    padding-top: 24px;
}
.root {
    align-self: center;
    width: 100%;
    @include screen('desktop') {
        width: 560px;
    }
}

.selectIcon,
.countrySelectIcon {
    position: absolute;
    right: 20px;
    top: 55%;
    color: $button-light;
}
.countrySelectIcon {
    top: 70%;
}
