@import '@/styles/forms';
.container {
    margin: 0 auto;

    @include screen('small-mobile') {
        max-width: 350px;
    }
    @include screen('xsmall-tablet') {
        max-width: 560px;
    }
}
