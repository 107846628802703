@import '@/styles/mixins';

.animatedDiv {
    position: relative;
    z-index: 120;
}

.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 60px 20px;
    background-color: rgba(17, 20, 20, 0.8);
    animation: smoothBg 0.3s ease-in forwards;

    @include screen('xsmall-tablet') {
        padding: 60px 50px;
    }
}

.root {
    width: $mobile-width;
    max-width: 100%;
    height: auto;
}
.container {
    background-color: $primary;
    padding: 32px 20px;
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 32px;

    @include screen('xsmall-tablet') {
        min-width: 310px;
    }
    .row {
        display: flex;
        flex-direction: column;
        gap: 16px;
        .heading {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding-bottom: 40px;
        }
    }
}
.buttonGroup {
    display: flex;
    gap: 16px;
}
.button {
    width: 167px;
    max-width: 100%;
}
