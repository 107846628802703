@import '@/styles/mixins';
@import '@/styles/forms';

.errors {
    background-color: $alert-error;
    color: $text-on-alert-error;
    border-radius: 20px;
    margin-bottom: 20px;
    li {
        text-align: center;
        padding: 20px;
    }
}
